import MetaTags from '../../../Meta/MetaTags'
import {useChatbotContext} from '../../context/ChatbotContext'
import Chatbot from '../../modules/chatbot/Chatbot'
import SidePanel from '../../modules/chatbot/components/SidePanel'

const ChatBotPage = () => {
  const {showSlider, setShowSlider} = useChatbotContext()
  return (
    <>
      {/* meta tags for SEO */}
      <MetaTags URL={window.location.href} />
      {!showSlider ? (
        <button
          className='primary-btn w-100 border-0 position-fixed z-index-1 d-block d-lg-none'
          style={{top: '45px'}}
          onClick={() => setShowSlider(true)}
        >
          Set Preferenses
        </button>
      ) : null}
      <section className='position-relative mt-20 mt-lg-0'>
        <div className='side-panel-container position-fixed z-index-1'>
          <SidePanel />
        </div>
        <Chatbot />
      </section>
    </>
  )
}

export {ChatBotPage}
