import axios from 'axios'
import ReactGA from 'react-ga4'
import {toPng} from 'html-to-image'
import {toast} from 'react-toastify'

let measurementIdForGA4Analytics =
  process.env.REACT_APP_GA_MEASUREMENT_ID_FOR_WEB3_DEV || 'YOUR_DEFAULT_MEASUREMENT_ID'
ReactGA.initialize(measurementIdForGA4Analytics)
// streamId = 6704117336 ,  6704136434
//send analytics
export const sendAnalytics = async () => {
  ReactGA.send({hitType: 'pageview', page: window.location.pathname, title: 'Home Page'})
}

//send data to spread sheet
export const sendToSheets = async (
  user: string,
  bot: string,
  receiverEmail: string,
  date: any,
  domain: string
) => {
  // 
  const encodedUserMessage = encodeURIComponent(user)
  const encodedBotMessage = encodeURIComponent(bot)
  const receiver_email = encodeURIComponent(receiverEmail)
  const current_date = encodeURIComponent(date)
  const current_domain = encodeURIComponent(domain)
  const scriptUrl = `${process.env.REACT_APP_GOOGLE_SHEETS_DEPLOYMENT_URL}?current_date=${current_date}&domain=${current_domain}&receiver_email=${receiver_email}&user_msg=${encodedUserMessage}&bot_msg=${encodedBotMessage}&host=my_web3`
  try {
    const response = await fetch(scriptUrl)
    if (response.status === 200) {
      //   const result = response.data
      //   console.log('Message sent successfully. Row:', result)
      return
    } else {
      console.error('Error sending message:', response.statusText)
    }
  } catch (error) {
    console.error('Error sending message:', error)
  }
}

// parse the raw html into the simple string
export const htmlToText = (htmlString: string) => {
  // Create a new DOMParser
  var parser = new DOMParser()

  // Parse the HTML string
  var doc = parser.parseFromString(htmlString, 'text/html')

  // Extract the text content
  var textContent = doc.body.textContent || ''

  return textContent.trim()
}

export const getRandomNumber = (lowerLimit: number, upperLimit: number) => {
  // Generate a random number within the specified range (inclusive of both limits)
  const randomNumber = Math.floor(Math.random() * (upperLimit - lowerLimit + 1)) + lowerLimit
  return randomNumber
}

export const downloadMessage = (messageId: string, imageName: string) => {
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)

  const chartContainer = document.getElementById(messageId)

  if (chartContainer) {
    toPng(chartContainer, {cacheBust: false, backgroundColor: 'white'})
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = imageName
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        notifyError('An error occurred.')
      })
  }
}

export const extractSubdomainFromCurrentUrl = () => {
  // Get the hostname from the current URL
  const domain = window.location.hostname

  // Split the hostname by the dots to separate the parts
  const parts = domain.split('.')

  // Remove the top-level domain (TLD) part
  if (parts.length > 2) {
    parts.pop() // This removes the last part (TLD)
  }

  // The remaining parts are the subdomain(s), joined with a dot
  const subdomain = parts.join('.')

  // Return the subdomain, which will be 'dev.chloee' for your example URL
  return subdomain
}
