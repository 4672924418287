import StackBarChart from '../../charts/StackBarChart'
import CustomOverlayTooltip from '../../components/CustomOverlayTooltip'
import {downloadMessage} from '../core/chatbotHelper'
import FeedbackSection from '../../components/feedbackSection'
import ShareAbleBubble from '../../components/ShareAbleBubble'
import SBLOCChart from '../../charts/SBLOCChart'
import ReactMarkdown from 'react-markdown'
interface Props {
  id: string
  message: string
  userRole: string
  chartData: any
  prevChartData: any
  imageSrc: string
  messageType?: string
}

const Threads: React.FC<Props> = (props) => {
  // destructuring props
  const {message, userRole, chartData, prevChartData, imageSrc, messageType, id} = props

  let sblocChartData
  let normalChartData
  if (chartData && !Array.isArray(chartData)) {
    sblocChartData = chartData.sbloc.result
    normalChartData = chartData.compare
  } else {
    normalChartData = chartData
  }

  return (
    <>
      {userRole === 'user' ? (
        <div className='message-container d-flex mb-7 justify-content-end'>
          <ReactMarkdown className={`${userRole}-chat mb-0 px-4 pt-3`}>{message}</ReactMarkdown>
          <img alt='Logo' src={imageSrc} className='avatars' />
        </div>
      ) : !messageType ? (
        <>
          <div id={id} className='d-flex p-md-2 mb-7'>
            <img alt='Logo' src={imageSrc} className='avatars' />

            <div
              className={
                chartData
                  ? 'bot-container message-container w-100'
                  : 'bot-container message-container'
              }
            >
              <div className={`${userRole}-chat mb-0 p-3`}>
                <ReactMarkdown>{message}</ReactMarkdown>
              </div>

              {sblocChartData && (
                <>
                  <SBLOCChart data={sblocChartData}></SBLOCChart>
                </>
              )}

              {normalChartData && normalChartData.length !== 0 && (
                <>
                  <div className='d-flex'>
                    {/* prev chart */}
                    {/* {normalChartData[0]?.is_comparison && prevChartData.length >= 1 ? (
                      <>
                        <div className='w-100'>
                          <p className='pink-text mb-0 text-center'>Previous chart</p>
                          <StackBarChart data={prevChartData} />
                        </div>
                      </>
                    ) : null} */}
                    {/* new chart */}
                    <div className='w-100'>
                      {/* {normalChartData[0]?.is_comparison && prevChartData.length >= 1 ? (
                        <p className='pink-text mb-0 text-center'>New chart</p>
                      ) : null} */}
                      <StackBarChart data={normalChartData} />
                    </div>
                  </div>
                  <div className='d-flex justify-content-end align-items-center my-5'>
                    <CustomOverlayTooltip
                      content='Please note that the information may not be real-time and could have changed since the last update.'
                      className='tooltip-logo'
                    />
                    {normalChartData[0]?.citation?.link ? (
                      <a
                        className='ms-2 me-4'
                        target='_blank'
                        href={normalChartData[0]?.citation?.link}
                        rel='noreferrer'
                      >
                        Data Source
                      </a>
                    ) : (
                      <p className='paragraph-small ms-2 me-4 mb-0'>Data Disclaimer</p>
                    )}
                  </div>
                </>
              )}

              {/* download and share button */}
              {id !== 'message0' && id !== 'message1' && (
                <div className='d-flex justify-content-end align-items-center my-3'>
                  <section>
                    <FeedbackSection sectionName={message} />
                  </section>
                  <button
                    className='chat-button ms-5'
                    title='Download'
                    onClick={() => downloadMessage(id, 'Message.png')}
                  >
                    <i className='fa fa-arrow-down' style={{color: '#fff'}} />
                  </button>
                  <ShareAbleBubble messageId={id} />
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className='d-flex p-md-2 mb-7'>
          <img alt='Logo' src={imageSrc} className='avatars' />

          <ReactMarkdown className={`localy-generated-chat bot-container mb-0 px-4 pt-3 ml-2`}>
            {message}
          </ReactMarkdown>
        </div>
      )}
    </>
  )
}

export default Threads
