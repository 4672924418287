import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import useFocus from '../../hooks/useFocus'
import {IMessagesInfo, IResponseBotObject, ISidePanel} from '../core/_model'
import {toast} from 'react-toastify'
import {
  extractSubdomainFromCurrentUrl,
  getRandomNumber,
  htmlToText,
  sendAnalytics,
  sendToSheets,
} from '../core/chatbotHelper'
import DOMPurify from 'dompurify'
import {ERROR} from '../../../../constants/AppConstants'
import {filterUserInput, submitChatRequest} from '../core/_request'
import {
  informationGatheringStrings,
  investmentTipsAndSuggestions,
  searchInProgressStrings,
  welcomeGreetingStrings,
} from '../core/TipsAndSuggestions'
import useScreenWidth from '../../hooks/useScreenWidth'
import {useChatbotContext} from '../../../context/ChatbotContext'
import {useAuth} from '../../../../modules/auth'

export const useChatBotState = () => {
  // screen width custom hook
  const screenWidth = useScreenWidth()

  const {currentUser} = useAuth()

  const {sidePanelPreferencesObj, setSidePanelPreferencesObj, setShowSlider} = useChatbotContext()

  let domainName = extractSubdomainFromCurrentUrl()

  // risk tolerance types
  const riskTypes = ['Low', 'Medium', 'High']
  // default preffered assets
  const defaultPrefferedAssets = ['Equities', 'Fixed Income', 'Cash', 'Stocks', 'Commodities']

  // define the validation schema for side-panel using Yup
  const ComparePerformanceSchema = Yup.object().shape({
    starting_amount: Yup.number(),
    contributions: Yup.number(),
    years_to_grow: Yup.number(),
    risk_tolerance: Yup.string(),
    preffered_assets: Yup.array(),
  })

  // define the initial form values for side-panel
  const initialValues: ISidePanel = {
    starting_amount: sidePanelPreferencesObj.starting_amount,
    contributions: sidePanelPreferencesObj.contributions,
    years_to_grow: sidePanelPreferencesObj.years_to_grow,
    risk_tolerance: sidePanelPreferencesObj.risk_tolerance,
    preffered_assets: sidePanelPreferencesObj.preffered_assets,
  }

  // formik handle submit
  const handleFormSubmit = async (values: ISidePanel) => {
    setSidePanelPreferencesObj(values)
  }

  // formik
  const formik = useFormik<ISidePanel>({
    initialValues,
    enableReinitialize: true,
    validationSchema: ComparePerformanceSchema,
    onSubmit: handleFormSubmit,
  })

  // text area ref hook
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  // setting isFirstRender to true to avoid form submission on first render
  const isFirstRender = useRef(true)

  const [userResponse, setUserResponse] = useState<string>('')
  const [botResponse, setBotResponse] = useState<IResponseBotObject>({
    message: '',
    sender: 'bot',
  })
  const [previousBarData, setPreviousBarData] = useState<any>({})
  const [threadId, setThreadId] = useState<string | null>(null)
  const [messages, setMessages] = useState<IMessagesInfo[]>([])
  const [chartData, setChartData] = useState<any[]>([])
  const [prevChartData, setPrevChartData] = useState<any[]>([])
  const [showChat, setShowChat] = useState<boolean>(false)
  const {inputRef, focusInput} = useFocus()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false)
  const [showSuggestedBubble, setShowSuggestedBubble] = useState<boolean>(true)
  const [suggestedMessages, setSuggestedMessages] = useState([
    {
      id: 1,
      message: 'How can I start investing with a small budget?',
    },
    {
      id: 2,
      message: 'Can you show me the growth of saving $50 per month versus $100 in an investment?',
    },
    {
      id: 3,
      message: `I've saved up $1,000. What's the best way to invest this amount?`,
    },
    {
      id: 4,
      message: 'If I start investing now, what might my portfolio look like after 5 years?',
    },
  ])

  const notifyError = (msg: string) => toast.error(msg)
  useEffect(() => {
    sendAnalytics()
  }, [])

  useEffect(() => {
    if (showChat) {
      focusInput()
    }
  }, [showChat, focusInput])

  // stacking up messages
  useEffect(() => {
    if (messages.length === 0) {
      submitChatRequestHandler()
    } else {
      let temp2 = [...messages]
      const botResponseFormatted: IMessagesInfo = {
        message: botResponse.message,
        sender: 'bot',
        chartData: chartData,
        prevChartData: prevChartData,
        messageType: botResponse.type,
      }

      temp2.push(botResponseFormatted)
      if (!botResponse.type) {
        temp2 = temp2.filter((message) => {
          return message.messageType === undefined || message.sender === 'user'
        })
      }
      // to keep track of whole conversation
      setMessages(temp2)
      setUserResponse('')
    }
  }, [botResponse])

  // adjust text area(search bar) height
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '30px' // reset the height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px` // set the height based on content
    }
  }, [userResponse])

  // set formik side-panel form values
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const timeoutId = setTimeout(() => {
      formik.handleSubmit()
    }, 100)
    return () => clearTimeout(timeoutId)
  }, [formik.values])

  // fetching personalized-form from local storage
  const personalizationFormObj = localStorage.getItem('personalizationFormObj')
  const parsedPersonalizationFormObj = personalizationFormObj
    ? JSON.parse(personalizationFormObj)
    : {}

  // // calculate gains
  // const calculateGains = (starting_amount: number, contributions: number, future_value: number) => {
  //   return future_value - contributions - starting_amount
  // }

  // // calculate future value
  // const calculateFutureValue = (
  //   starting_amount: number,
  //   contributions: number,
  //   contributions_frequency: number,
  //   rate_of_return: number,
  //   years_to_grow: number,
  //   investment_name: string
  // ) => {
  //   const n = contributions_frequency !== 0 ? contributions_frequency : 1
  //   const t = years_to_grow
  //   const i = rate_of_return / 100 / n
  //   const compoundFactor = Math.pow(1 + i, n * t)
  //   const principalComponent = starting_amount * compoundFactor
  //   const contributionComponent = contributions * ((compoundFactor - 1) / i)
  //   const futureValue = principalComponent + contributionComponent
  //   const calculated_contributions = contributions * contributions_frequency * years_to_grow
  //   const gains = calculateGains(starting_amount, calculated_contributions, futureValue)

  //   return {
  //     starting_amount: starting_amount,
  //     contributions: calculated_contributions,
  //     contributions_frequency: contributions_frequency,
  //     rate_of_return_value: rate_of_return,
  //     years_to_grow: years_to_grow,
  //     investment_name: investment_name,
  //     future_value: futureValue,
  //     gains: gains,
  //     is_loss: gains < 0 ? true : false,
  //   }
  // }

  // // filtering default investment based on the user preference
  // const findAssets = (riskType: string): any => {
  //   let chartDataArray

  //   if (riskType === 'Low') {
  //     chartDataArray = riskToleranceAssets[0].map((asset: any) =>
  //       calculateFutureValue(
  //         parsedPersonalizationFormObj?.initial_investment_amount ?? 1000, // starting amount
  //         parsedPersonalizationFormObj?.contributions ?? 0, // contributions
  //         parsedPersonalizationFormObj?.contributions_frequency, // contributions frequency
  //         asset.ROR, // rate of return
  //         5, // years to grow
  //         asset.label //investment name
  //       )
  //     )
  //     return chartDataArray
  //   }
  //   if (riskType === 'Medium') {
  //     chartDataArray = riskToleranceAssets[1].map((asset: any) => {
  //       return calculateFutureValue(
  //         parsedPersonalizationFormObj?.initial_investment_amount ?? 1000, // starting amount
  //         parsedPersonalizationFormObj?.contributions ?? 0, // contributions
  //         parsedPersonalizationFormObj?.contributions_frequency, // contributions frequency
  //         asset.ROR, // rate of return
  //         5, // years to grow
  //         asset.label //investment name
  //       )
  //     })
  //     return chartDataArray
  //   }
  //   if (riskType === 'High') {
  //     chartDataArray = riskToleranceAssets[2].map((asset: any) =>
  //       calculateFutureValue(
  //         parsedPersonalizationFormObj?.initial_investment_amount ?? 1000, // starting amount
  //         parsedPersonalizationFormObj?.contributions ?? 0, // contributions
  //         parsedPersonalizationFormObj?.contributions_frequency, // contributions frequency
  //         asset.ROR, // rate of return
  //         5, // years to grow
  //         asset.label //investment name
  //       )
  //     )
  //     return chartDataArray
  //   }
  // }

  const sidePanelObj = ` Here are the user's preferences starting_amount=${sidePanelPreferencesObj.starting_amount}, contributions: ${sidePanelPreferencesObj.contributions}, contributions_frequency: 12, years_to_grow: ${sidePanelPreferencesObj.years_to_grow}, risk_tolerance: ${sidePanelPreferencesObj.risk_tolerance}, asset_preference: ${sidePanelPreferencesObj.preffered_assets}. Note: Please use these values while answering to the user, and if the values are null than use the personalized or default values. I repeat if the values are null than use the personalized or default values.`

  // default start message
  const startMessage = parsedPersonalizationFormObj?.risk_tolerance
    ? 'Say Hi to user at the start of each new thread and inform user that we have collected your personalization information and note please keep the answer very short. Also, ask user What questions can I help you with? or similar questions in friendly manner.'
    : 'Say Hi to user at the start of each new thread. Also, ask user What questions can I help you with? or similar questions in friendly manner'

  const submitChatRequestHandler = async () => {
    let timerId: any
    let counting = 0
    let message = ''
    try {
      setLoading(true)
      let messages: IMessagesInfo[] = []
      timerId = setInterval(() => {
        counting += 1
        switch (counting) {
          case 2:
            message += welcomeGreetingStrings[getRandomNumber(0, 19)]
            break
          case 6:
            message = welcomeGreetingStrings[getRandomNumber(0, 19)]
            break
        }

        if (message) {
          const newMessage: IMessagesInfo = {
            message: message,
            sender: 'bot',
            chartData: null,
            prevChartData: null,
            messageType: 'generatedFromLocal',
          }
          messages.push(newMessage)
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, newMessage]
            return updatedMessages
          })
          message = ''
        }

        if (counting >= 10) {
          clearInterval(timerId)
        }
      }, 1000)

      const res = await submitChatRequest(
        startMessage,
        threadId,
        personalizationFormObj,
        currentUser?.id
      )

      const {text, existingThreadId} = res.data
      const newMessage = {
        message: text,
        sender: 'bot',
        chartData: null,
        prevChartData: null,
      }
      messages.push(newMessage)
      setMessages(messages)

      !threadId && setThreadId(existingThreadId)
    } catch (error: any) {
      if (error?.response?.status === 429) return notifyError(ERROR.too_many_requests)
        else if (error?.response?.status === 403)
          return notifyError('Oops! You have reached your maximum tokens limit.')
      else notifyError(ERROR.message)
      // console.log(error?.response)
    } finally {
      clearInterval(timerId)
      setLoading(false)
      setShowChat(true)
    }
  }

  // event handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserResponse(e.target.value)
  }

  // handle form submit
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, defaultMessage = '') => {
    e.preventDefault()
    setShowSuggestedBubble(false)
    setShowSlider(false)
    const userRes = defaultMessage.length > 2 ? defaultMessage : userResponse
    if (userRes.length < 1) return
    if (userRes === 'Thankyou') {
      setShowFeedbackModal(true)
      return setUserResponse('')
    }
    setShowSuggestedBubble(false)
    let tempArray = [...messages]
    const botResponseFormatted: IMessagesInfo = {
      message: userRes,
      sender: 'user',
      chartData: null,
      prevChartData: null,
    }
    tempArray.push(botResponseFormatted)
    setMessages(tempArray)
    setLoading(true)
    let timerId: any

    try {
      const responseFromFilter = await filterUserInput(userRes)
      if (!responseFromFilter.data) {
        setBotResponse({
          message: "I'm sorry, I can only answer questions related to finance and investment",
          sender: 'bot',
        })
        setChartData([])
        return
      }
      let counting = 0
      let message = ''

      timerId = setInterval(() => {
        counting += 1
        switch (counting) {
          case 10:
            message += informationGatheringStrings[getRandomNumber(0, 54)]
            break
          case 20:
            message += searchInProgressStrings[getRandomNumber(0, 99)]
            break
          case 30:
            message += investmentTipsAndSuggestions[getRandomNumber(0, 177)]
            break
          case 50:
            message += investmentTipsAndSuggestions[getRandomNumber(0, 177)]
            break
        }

        if (message) {
          setBotResponse({
            message: message,
            sender: 'bot',
            type: 'generatedFromLocal',
          })
        }
        message = ''
      }, 1000)

      // const startTime: any = new Date()
      const res = await submitChatRequest(
        userRes + sidePanelObj,
        threadId,
        personalizationFormObj,
        currentUser?.id
      )

      // const endTime: any = new Date()
      // const elapsedMilliseconds = endTime - startTime
      // const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000)

      // console.log(`Request took ${elapsedSeconds} seconds`)
      const {text, existingThreadId, chart_data} = res.data
      if (
        chart_data.length === 1 &&
        chart_data[0].investment_name === previousBarData?.investment_name &&
        chart_data[0].investment_name !== undefined
      ) {
        let tempArray = [...chart_data] // Create a new array to avoid mutating the original
        tempArray.push(previousBarData)
        setChartData(tempArray)
      } else {
        setPrevChartData(chartData)
        setChartData(chart_data && chart_data)

        setSidePanelPreferencesObj(
          chart_data.length >= 1
            ? {
                starting_amount: chart_data[0]?.starting_amount,
                contributions: chart_data[0]?.original_contributions,
                years_to_grow: chart_data[0]?.years_to_grow,
                risk_tolerance: chart_data[0]?.risk_tolerance,
                preffered_assets: sidePanelPreferencesObj?.preffered_assets,
              }
            : sidePanelPreferencesObj
        )
      }

      !existingThreadId && setThreadId(existingThreadId)
      // set response text

      setBotResponse({
        message: res.data?.response ? res.data.response : DOMPurify.sanitize(text),
        sender: 'bot',
      })

      if (chart_data.length === 1) {
        setPreviousBarData({...chart_data[0], oldValue: true})
      }
      const date = new Date()

      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`

      const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`

      const receiverEmail = extractEmailKeyFromUrl(window.location.href) ? extractEmailKeyFromUrl(window.location.href) : currentUser?.email

      sendToSheets(
        userResponse,
        htmlToText(res.data?.response ? res.data.response : DOMPurify.sanitize(text)),
        receiverEmail ? receiverEmail : '',
        formattedDate + ' ' + formattedTime,
        domainName
      )
    } catch (error: any) {
      if (error?.response?.status === 429) return notifyError(ERROR.too_many_requests)
        else if (error?.response?.status === 403)
          return notifyError('Oops! You have reached your maximum tokens limit.')
      else notifyError(ERROR.message)
    } finally {
      clearInterval(timerId)
      setLoading(false)
      setShowChat(true)
    }
  }

  // handle 'enter' key down
  const handleKeyDown = (event: any) => {
    // check if Enter is pressed without the Shift key
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // prevent new line
      handleSubmit(event)
    }
  }

  // extract email from URL
  const extractEmailKeyFromUrl = (url: string): string | null => {
    const urlParams = new URLSearchParams(url.split('?')[1])
    const emailValue = urlParams.get('email')
    return emailValue
  }

  // handle suggested messages
  const handleSuggestedMessages = (e: any, message: string) => {
    setUserResponse(message)
    handleSubmit(e, message)
  }

  return {
    formik,
    messages,
    isLoading,
    showSuggestedBubble,
    suggestedMessages: screenWidth > 991 ? suggestedMessages : suggestedMessages.slice(0, 2),
    userResponse,
    inputRef,
    textAreaRef,
    riskTypes,
    prevChartData,
    defaultPrefferedAssets,
    handleSubmit,
    handleKeyDown,
    handleInputChange,
    handleSuggestedMessages,
    showFeedbackModal,
    setShowFeedbackModal,
  }
}
